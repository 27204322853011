export const splitFloorAreaVisuals = [
  {
    name: "Demo",
    markdown: `![Split floor area GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const splitFloorArea = {
  order: 33,
  name: "Split floor areas",
  keyword: "divide subdivide demise",
  subcategory: "Properties",
  markdown: `# Split floor areas

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  There are a variety of reasons why a {% inlineRouterLink articleId="add-a-floor-area" %}floor area{% /inlineRouterLink %} may need to be split into multiple pieces. Read on to learn how and why to do this in the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.

  {% callout type="tip" %}
  **Tip:** Splitting a floor area does not change its {% inlineRouterLink %}area measures{% /inlineRouterLink %}, so be sure to double-check that the size is still accurate after you complete the relocation.
  {% /callout %}

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="add-a-floor-area" %}floor area{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="splitFloorArea" /%}.
  4. In the pop-up that appears above the button, ...
  5. In the pop-up that appears above the button, click **Save**. The floor area splits according to your choices.
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$splitFloorAreaVisuals /%}
  `,
};
